.wrap {
    position: relative;
    margin-right: 20px;
    margin-bottom: 40px;
    min-width: 1180px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.rowWrap {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.sortControls {
    display: flex;
    gap: 8px;
}

.titleWrapRight { 
    margin-left: auto;
}

.row {
    position: relative;
    transform: none;
    cursor: pointer;
}

.addButton {
    padding: 5px 12px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.priceCell {
    text-align: right;
    // font-weight: bold;
}
.positionsCell {
    font-size: small;
    color: #777;
}
.positionsCell p {
    margin-bottom: 0.5rem;
}
.positionsH {
    font-weight: normal;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100px;
}
