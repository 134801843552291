.body {
    transform: scale(1, 1);
}

.row {
    position: relative;
}

.alignRight {
    text-align: right;
}

.alignCenter {
    text-align: center;
}

.checkboxLg {
    width: 25px !important;
    height: 25px !important;
}

.imageRow {
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.filtered {
    color: #BA1274;
    font-weight: 800;
}

.default {
    cursor: default;
}

.pointer {
    cursor: pointer;
}
