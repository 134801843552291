.wrap {
    position: relative;
    margin-right: 20px;
    margin-bottom: 40px;
    min-width: 1180px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.topContainer {
    background-color: rgb(230, 230, 230);
    margin: 16px 0;
    padding: 20px 8px;
}
